<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="customerSearch.customerName"
                    :label="$t('customer.name')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="customerSearch.customerPhone"
                    :label="$t('customer.phone')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="customerSearch.customerEmail"
                    :label="$t('customer.email')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-select
                    v-model="userTypeSelected"
                    :items="UserTypeList"
                    :label="$t('evoucher.issuance.type')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
                @keyup.enter="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('customer.customerList')"
          color="green"
          flat
          full-width
        >
          <div @mouseleave="$refs.tblCustomerList.expanded=[]">
            <v-data-table
              ref="tblCustomerList"
              :no-data-text="$t('common.noDataAvailable')"
              :headers="customerHeaders"
              :items="customerList"
              :single-select="true"
              :expand="false"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="text--darken-3 font-medium"
                  v-text="$t(header.text)"
                />
              </template>
              <template v-slot:items="props">
                <tr
                  :style="`background-color: ${props.item.type == UserType.GHOST ? '#e5e5e5' : ''};`"
                  @click="isAdmin() ? props.expanded=!props.expanded : null"
                  @mouseover="isAdmin() ? props.expanded=true : null">
                  <td
                    v-for="(header, index) in customerHeaders"
                    :key="header.value + index">
                    <span v-if="header.value == 'email'">{{ props.item.email }}</span>
                    <span v-else-if="header.value == 'gender'">{{ $t(getGender(props.item[header.value])) }}</span>
                    <span v-else-if="header.value == 'birthDate'">{{ formatBirthDate(props.item[header.value]) }}</span>
                    <span
                      v-else-if="header.value == 'actions'"
                      style="white-space: nowrap;">
                      <v-tooltip
                        v-if="!props.item.fromThirdParty"
                        top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            color="success"
                            style="color: #ffffff;"
                            v-on="on"
                            @click="onUpdateCustomerInfo(props.item)"
                          >
                            <v-icon dark>mdi-account-edit</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('customer.info') }}</span>
                      </v-tooltip>
                    </span>
                    <span v-else>{{ props.item[header.value] }}</span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="customerPaginate.totalPage"
      :current-page="customerPaginate.currentPage"
      :row-per-page="customerPaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <loading-bar :is-loading="isLoading" />
    <updateCustomerInfoModal
      ref="updateCustomerInfo"
      @updateCustomerInfoSuccess="onUpdateCustomerInfoSuccess"/>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import stringUtils from 'utils/stringUtils'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import UpdateCustomerInfoModal from './UpdateCustomerInfoModal'
// import ToastType from 'enum/toastType'
import UserType from 'enum/userType'
import { FOR_CONASI_TARGET } from 'utils/constants'
export default {
  components: {
    UpdateCustomerInfoModal
  },
  data: () => ({
    customerHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        width: '3%'
      },
      {
        sortable: false,
        text: 'users.fullName',
        value: 'fullname'
      },
      {
        sortable: false,
        text: 'user.email',
        value: 'email'
      },
      {
        sortable: false,
        text: 'users.phone',
        value: 'phone'
      },
      {
        sortable: false,
        text: 'storeChannel.title',
        value: 'storeChannel'
      },
      {
        sortable: false,
        text: 'customer.linkEmail',
        value: 'linkEmail'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        width: '5%'
      }
    ],
    customerList: [],
    customerPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    customerSearch: {
      customerName: null,
      customerCode: null,
      customerStatus: null,
      customerAddress: null,
      customerPhone: null,
      customerEmail: null
    },
    isLoading: false,
    UserType: {
      USER: UserType.USER,
      GHOST: UserType.GHOST
    },
    UserTypeList: stringUtils.UserTypeList,
    userTypeSelected: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'CUSTOMER_LIST_DATA',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    CUSTOMER_LIST_DATA () {
      let res = this.CUSTOMER_LIST_DATA
      let data = res.results
      this.customerPaginate.currentPage = res.paginate.currentPage
      this.customerPaginate.totalPage = res.paginate.totalPage
      this.customerPaginate.rowPerPage = res.paginate.perPage
      this.customerList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let customerObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          fullname: functionUtils.concatFullname(data[i].last_name, data[i].first_name),
          phone: data[i].profile && data[i].profile.phone,
          email: data[i].email,
          gender: data[i].profile && data[i].profile.gender,
          birthDate: data[i].profile && data[i].profile.dob,
          id: data[i].id,
          isActive: data[i].profile && data[i].profile.is_active,
          isLoading: false,
          type: data[i].profile && data[i].profile.type,
          storeChannel: data[i].store_channel,
          fromThirdParty: data[i].from_third_party,
          linkEmail: data[i].verification_email
        }
        this.customerList.push(customerObj)
      }
    }
  },
  created () {
    this.getCustomerList()
    if (!FOR_CONASI_TARGET || !functionUtils.hasEntityRoles('hasManageWebPortal')) {
      this.customerHeaders = this.customerHeaders.filter(element => {
        return (element.value !== 'storeChannel' && element.value !== 'linkEmail')
      })
    }
  },
  methods: {
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Update customer info success
     */
    onUpdateCustomerInfoSuccess: function () {
      this.getCustomerList()
    },
    /**
     * Show modal update customer info
     */
    onUpdateCustomerInfo: function (item) {
      this.$refs.updateCustomerInfo.onShowModal(item, false)
    },
    /**
     * Format birthdate
     */
    formatBirthDate: function (date) {
      return dateUtils.formatBeautyDate(date, null)
    },
    /**
     * Get gender
     */
    getGender: function (gender) {
      return functionUtils.getGender(gender)
    },
    /**
     * Search
     */
    onSearch: function () {
      this.getCustomerList()
    },
    /**
     * Get list entities by type
     */
    getCustomerList: function () {
      let filter = {
        params: {
          page: this.customerPaginate.currentPage,
          name: this.customerSearch.customerName,
          phone: this.customerSearch.customerPhone,
          email: this.customerSearch.customerEmail,
          type: this.userTypeSelected,
          isGetFromThirdParty: true
        }
      }
      this.isLoading = true
      this.GET_CUSTOMER_LIST(filter).then(
        function (res) {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.customerPaginate.currentPage = page
      this.getCustomerList()
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_CUSTOMER_LIST'
    ])
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/material-dashboard/_colors.scss";
.border-style {
  border-bottom: 2px solid $border-color;
}
.cursor-pointer {
  cursor: pointer !important;
}
</style>
